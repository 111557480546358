/* This example requires Tailwind CSS v2.0+ */
import {
  CalendarIcon,
  ChatIcon,
  CheckIcon,
  ExclamationIcon,
  MapIcon,
  PlusIcon,
  UsersIcon,
  ViewBoardsIcon,
  ViewListIcon,
} from '@heroicons/react/outline'
import { Title, Button, Badge } from '@/components'

const features = [
  {
    name: 'Detailed Listings',
    icon: ViewListIcon,
    description:
      'Include the costs of utilities, the details behind parking and laundry, as well as how loud the neighbours are.',
  },
  {
    name: 'Neighbourhood Guide',
    icon: MapIcon,
    description: 'Help renters find out about the community around them.',
  },
  {
    name: 'Communicate directly with tenants',
    icon: ChatIcon,
    description:
      'Chasing emails and text messages can get messy fast. Message with interested tenants directly through the app.',
  },
  {
    name: 'Schedule and manage showings',
    icon: CalendarIcon,
    description:
      'Create a showing schedule and allow tenants to schedule a time that works for them to view the rental',
  },
]
const checklist = ['Unlimited showings', 'Custom shortcode URL', 'Featured posting', '24/7 support']

export const Pricing = () => {
  return (
    <div className="relative transition bg-zinc-900">
      <div className="absolute inset-0" aria-hidden="true">
        <div className="absolute inset-y-0 right-0 w-1/2 lg:bg-brand-500 transition" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:px-8 lg:grid lg:grid-cols-2 ">
        <div className="transition py-16 px-6 sm:py-32 sm:px-6 lg:px-0 lg:pr-8">
          <div className="max-w-lg mx-auto lg:mx-0">
            <p className="text-melrose font-bold tracking-tight  inline-block mb-2">Focus on running your business.</p>
            <div className="max-w-lg">
              <Title size="2xl" as="h2" className="!text-zinc-50">
                Post your rental for free. Yes, <span className="text-honeysuckle ">free.</span>
              </Title>
            </div>

            <dl className="mt-12 space-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute h-12 w-12 flex items-center justify-center bg-brand-500 rounded-lg shadow-lg shadow-brand/30">
                      <feature.icon className="h-6 w-6 text-brand-50" aria-hidden="true" />
                    </div>
                    <p className="ml-16  tracking-tight font-semibold sm:text-lg text-zinc-200">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-zinc-200 text-base font-medium">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="bg-brand-500 py-16 px-6 sm:py-24 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end transition">
          <div className="max-w-lg mx-auto w-full  lg:mx-0">
            <div className="mb-8">
              <h2 className="sr-only">Price</h2>
              <p className="relative flex justify-start max-w-sm mx-auto">
                <span className="flex-auto inline-flex flex-col text-center items-center">
                  <span className="mt-6 text-4xl font-extrabold text-zinc-100 tracking-tight">Go Premium</span>
                </span>
                <span className="flex-1 pointer-events-none h-12 w-full flex items-center justify-center mt-5 text-4xl font-extrabold text-brand-100 tracking-tight text-center">
                  for
                </span>

                <span className="flex-auto flex items-center flex-col text-center">
                  <span className="mt-2 text-6xl font-extrabold text-zinc-50 tracking-tight">$10</span>
                  <span className="mt text-base font-medium text-brand-100">per month</span>
                </span>
              </p>
            </div>
            <p className="mt font-bold text-center tracking-tight text-lg sm:text-2xl text-zinc-100">
              We donate 50% of every premium listing to a charity focused on supporting housing equity.
            </p>
            <div className="bg-green-50 rounded-md p-4 mt-6">
              <div className="flex items-center">
                <div className="flex-shrink-0 text-lg align-middle">
                  <span>🎉</span>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-700">
                    For launch, use the code <strong className="font-extrabold">HIFRIEND</strong> for your first free
                    listing.
                  </p>
                </div>
              </div>
            </div>
            <p className="mt-10 text-center tracking-tight text-zinc-100">Premium features include:</p>
            <ul className="mt-2 rounded overflow-hidden grid gap-px sm:grid-cols-2">
              {checklist.map((item) => (
                <li
                  key={item}
                  className="bg-brand-300 bg-opacity-50 py-4 px-6 flex items-center space-x-3 text-base text-white font-medium"
                >
                  <CheckIcon className="h-6 w-6 text-lime-400" aria-hidden="true" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>

            <Button internal url="/rentals/new" className="mt-8 w-full">
              Post your listing now
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
