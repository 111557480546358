import { Button, Title } from '@/components'
import Image from 'next/image'

import dishwasherIcon from '../../../public/icons/3d/dishwasher.png'

import hydroIcon from '../../../public/icons/3d/hydro.png'

import maintainIcon from '../../../public/icons/3d/maintain.png'
import noisyIcon from '../../../public/icons/3d/noisy.png'
import parkingIcon from '../../../public/icons/3d/parking.png'
import dryerIcon from '../../../public/icons/3d/dryer.png'

import petsIcon from '../../../public/icons/3d/pets.png'

export const ReviewsCTA = () => (
  <div className="lg:-mt-44  relative overflow-hidden">
    <div className="pb-[680px] lg:pt-64 lg:pb-80">
      <div className="relative sm:max-w-3xl lg:max-w-7xl mx-auto px-6 lg:px-8 sm:static lg:grid grid-cols-2">
        <div className="lg:max-w-lg col-span-1 order-2">
          <p className="text-brand-300 dark:text-melrose font-bold inline-block tracking-tight">
            We take the guesswork out of deciding where to rent.
          </p>
          <Title as="h2" size="2xl" className="mt-2">
            Real Reviews, <br /> by Real Renters
          </Title>
          <p className="mt-8 mb-4 text-lg  dark:text-zinc-300 text-zinc-600 tracking-tight font-medium">
            Deciding to rent an apartment is a big decision, and one that will involve lots of factors bang for your
            buck. Good Neighbour helps you make an informed choice by providing in-depth reviews from previous renters
          </p>
        </div>
        <div className="lg:col-span-1 relative order-1">
          <div className="mt-16 lg:mt-80">
            {/* Decorative image grid */}
            <div
              aria-hidden="true"
              className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
            >
              <div className="absolute transform  md:top-0 md:translate-x-8 lg:-left-16 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-0">
                <div className="flex items-center space-x-8 lg:space-x-8">
                  <div className="flex-shrink-0 grid grid-cols-1 gap-y-8">
                    <div className="w-32 sm:w-44 h-48 sm:h-60 rounded-t-full rounded-b-[60rem]  shadow-2xl relative bg-green-200 shadow-green-400/50 bg-opacity-70">
                      <div className="absolute sm:-left-2 top-10 z-10 h-32 sm:h-48 w-32 sm:w-48">
                        <Image src={hydroIcon} />
                      </div>
                    </div>
                    <div className="w-32 sm:w-44 h-48 sm:h-60 rounded-t-full rounded-b-[60rem]  shadow-2xl relative bg-brand-50 shadow-brand-200/50 bg-opacity-50">
                      <div className="absolute sm:top-4 top-10 z-10 h-32 sm:h-48 w-32 sm:w-48">
                        <Image src={noisyIcon} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 grid grid-cols-1 gap-y-8">
                    <div className="w-32 sm:w-44 h-48 sm:h-60 rounded-t-full rounded-b-[60rem]  shadow-2xl relative bg-cyan-200 shadow-cyan-400/50 bg-opacity-50">
                      <div className="absolute sm:-left-2 sm:top-6 top-10 z-10 h-32 sm:h-48 w-32 sm:w-48">
                        <Image src={parkingIcon} />
                      </div>
                    </div>
                    <div className="w-32 sm:w-44 h-48 sm:h-60 rounded-t-full rounded-b-[60rem]  shadow-2xl relative bg-yellow-200 shadow-yellow-400/50 bg-opacity-50">
                      <div className="absolute sm:-left-2 sm:top-6 top-10 z-10 h-32 sm:h-48 w-32 sm:w-48">
                        <Image src={maintainIcon} />
                      </div>
                    </div>
                    <div className="w-32 sm:w-44 h-48 sm:h-60 rounded-t-full rounded-b-[60rem]  shadow-2xl relative bg-indigo-200 shadow-indigo-400/50 bg-opacity-50">
                      <div className="absolute sm:-left-4 sm:top-6 top-10 z-10 h-32 sm:h-48 w-32 sm:w-48">
                        <Image src={dryerIcon} />
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 grid grid-cols-1 gap-y-8">
                    <div className="w-32 sm:w-44 h-48 sm:h-60 rounded-t-full rounded-b-[60rem]  shadow-2xl relative bg-lime-200 shadow-lime-400/50 bg-opacity-50">
                      <div className="absolute sm:-left-2 sm:top-8 top-10 z-10 h-32 sm:h-48 w-32 sm:w-48">
                        <Image src={petsIcon} />
                      </div>
                    </div>
                    <div className="w-32 sm:w-44 h-48 sm:h-60 rounded-t-full rounded-b-[60rem]  shadow-2xl relative bg-rose-200 shadow-rose-400/40 bg-opacity-50">
                      <div className="absolute sm:-left-2 sm:top-6 top-10 z-10 h-32 sm:h-48 w-32 sm:w-48">
                        <Image src={dishwasherIcon} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute -top-12 sm:top-auto lg:-right-52 sm:mt-6">
              <Button url="/reviews/new">Review your rental</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
