import { Button, Title } from '@/components'

export const Mission = () => (
  <div className="bg-zinc-900 dark:bg-zinc-800 relative">
    <div className="max-w-7xl mx-auto py-12 px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <div>
        <Title as="h2" size="xl" className="!text-zinc-200">
          We want to make finding and renting your home as stress-free and simple as possible.
        </Title>
        <p className="mt-8 mb-6 text-lg  text-zinc-300 tracking-tight font-medium">
          We believe that if the modern rental market can offer the same transparency and convenience of today's sharing
          economy; we can make a positive impact for both tenants and landlords.
        </p>
      </div>
      <div className="lg:ml-16 mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <Button className="w-full " url="/about" alt>
          Read more about our Mission
        </Button>
      </div>
    </div>
  </div>
)
