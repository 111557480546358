import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { Map, Marker, Popup } from 'react-map-gl'

import { Button, ButtonLink, ErrorBoundary } from '@/components'
import { AnimatePresence, motion } from 'framer-motion'
import clsx from 'clsx'
import { useWindowSize } from '@/hooks/useWindowSize'
import Link from 'next/link'
import { useQuery } from '@apollo/client'
import { ALL_LISTINGS_QUERY } from '@/queries'

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

const DynamicMap = dynamic(() => import('../Map'), {
  ssr: false,
})

export const Hero = ({ latitude, longitude }: { latitude: number; longitude: number; city: string | null }) => {
  const { loading, data } = useQuery(ALL_LISTINGS_QUERY, {
    variables: {
      first: 50,
      filters: {
        listingType: null,
        laundry: false,
        bedrooms: 0,
        bathrooms: 0,
        airConditioning: false,
        accessibility: false,
        parkingAvailable: false,
        petsWelcome: false,
        rentHigh: 10000,
        rentLow: 0,
        utilitiesIncluded: false,
        dishwasher: false,
      },
      longitude: Number(longitude),
      latitude: Number(latitude),
    },
  })

  const size = useWindowSize()
  const [mapReady, setMapReady] = useState(false)

  useEffect(() => {
    if (window) {
      setMapReady(true)
    }
  }, [])

  return (
    <div className="pt-0 sm:pt-12 relative pb-0 lg:pt-20 xl:pt-32 lg:pb-72 overflow-hidden  from-brand-500/10 dark:from-brand-500/10 via-white/10 dark:via-zinc-900/0 to-zinc-900/0 bg-opacity-5">
      <div className="w-full relative z-10 mx-auto px-6 lg:px-8 sm:max-w-6xl lg:max-w-7xl">
        <div>
          <div className="">
            <div className="mt-6 lg:w-3/5">
              <h1 className="text-zinc-700 dark:text-zinc-200 text-5xl font-sans font-extrabold tracking-tighter md:text-7xl xl:text-8xl text-center lg:text-left">
                Say goodbye to rental regret
              </h1>

              <p className="mx-auto lg:mx-0 max-w-xl lg:max-w-2xl mt-4 lg:mt-8 text-base sm:text-xl dark:text-zinc-300 text-zinc-600 tracking-tight font-medium text-center lg:text-left">
                The rental platform that takes the uncertainty out of renting by allowing current residents to share
                their renting experiences for others.
              </p>
            </div>

            <div className="mt-10 flex flex-col sm:flex-row justify-center lg:justify-start items-center gap-4 sm:gap-8">
              <Button url="/new" internal alt>
                Add your story now
              </Button>
              <ButtonLink url="/explore" internal>
                Explore reviews
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
      <ErrorBoundary>
        <div className="mt-6 lg:mt-0 w-full sm:mx-auto sm:max-w-3xl sm:px-6 h-[360px] lg:h-auto">
          <div className="py-6 relative lg:mt-12 lg:py-0 lg:absolute lg:inset-y-0 lg:-right-32 lg:w-1/2">
            <div className="relative px-6  sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-7xl lg:h-full lg:pl-0 transform">
              <AnimatePresence>
                {mapReady ? (
                  !loading && data.queryListings.edges?.length ? (
                    <motion.div
                      key="exploreMap"
                      className="rounded-lg overflow-hidden lg:rounded-none lg:rounded-l-2xl shadow-2xl mb-6 lg:absolute z-10 w-full  relative"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ delay: 0.4, ease: 'easeIn' }}
                    >
                      <DynamicMap data={data.queryListings.edges} config={{ latitude, longitude }} />
                    </motion.div>
                  ) : (
                    <motion.div
                      key="staticMap"
                      className={clsx(
                        'transition-all duration-500 rounded-lg overflow-hidden lg:rounded-none lg:rounded-l-xl shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] mb-6 relative lg:absolute z-10 w-full ',
                        !loading && !data.queryListings.edges.length ? '' : 'blur-sm animate-pulse',
                      )}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ delay: 0.5, ease: 'easeOut' }}
                    >
                      {!loading && !data.queryListings.edges.length && (
                        <div className="sr-only">
                          <p className="font-bold text-2xl tracking-tight text-white">
                            Do you rent here? <Link href="/new">Tell your story?</Link>
                          </p>
                        </div>
                      )}
                      <Map
                        style={{ width: '100%', height: size.width < 1024 ? 260 : 700 }}
                        latitude={latitude}
                        longitude={longitude}
                        zoom={13}
                        mapStyle="mapbox://styles/mapbox/outdoors-v11?optimize=true"
                        mapboxAccessToken={MAPBOX_TOKEN}
                        pitch={35}
                        attributionControl={false}
                        interactive={false}
                      >
                        <AnimatePresence>
                          {!loading && !data.queryListings.edges.length && (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ delay: 0.8, ease: 'easeOut' }}
                            >
                              <PopupPromo lat={latitude} lng={longitude} />
                              <Pin lat={latitude} lng={longitude} />
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Map>
                    </motion.div>
                  )
                ) : null}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}

const Pin = ({ lat, lng }: any) => (
  <Marker latitude={lat} longitude={lng} offset={[-12, 0]}>
    <svg
      className="text-zinc-800 fill-current stroke-0 animate-ping absolute"
      viewBox="0 0 16 16"
      width="24"
      height="24"
    >
      <circle cx="8" cy="8" r="8" className="text-zinc-800" />
    </svg>
    <svg className="text-zinc-800 fill-current stroke-0 absolute" viewBox="0 0 16 16" width="24" height="24">
      <circle cx="8" cy="8" r="8" className="text-zinc-800" />
    </svg>
  </Marker>
)

const PopupPromo = ({ lat, lng }: any) => {
  const size = useWindowSize()
  return (
    <>
      <Popup
        latitude={lat}
        longitude={lng}
        anchor={size.width <= 640 ? 'bottom' : 'bottom-right'}
        offset={5}
        closeOnClick={true}
        closeButton={false}
        className="z-10 hero-popup !max-w-fit"
      >
        <div className="flex flex-col items-center font-sans font-medium text-sm text-white tracking-tight" aria-hidden>
          <Link href="/new" passHref>
            <a className="px-6 py-2 outline-none">Rate your rental</a>
          </Link>
        </div>
      </Popup>
    </>
  )
}
